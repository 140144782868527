<template>
  <div class="accrual-table">
    <slot-table
      class="accrual-table__content"
      :headers="tableHeaders"
      :data="requests"
    >
      <template #column-0="{ row }">
        <accrual-number :value-number="row.id" />
      </template>

      <template #column-1="{ row }">
        <accrual-date :datetime="row.createdAt" />
      </template>

      <template #column-2="{ row }">
        <accrual-status :status-id="row.status_id" />
      </template>

      <template #column-3="{ row }">
        <accrual-number :value-number="row.userId" />
      </template>

      <template #column-4="{ row }">
        <accrual-number :value-number="row.summ" />
      </template>

      <template #column-5="{ row }">
        <accrual-approval
          :request-id="row.id"
          :status-id="row.status_id"
          @resolve-request="fetchRequests"
        />
      </template>
    </slot-table>

    <pagination
      v-if="amountOfPages > 1"
      class="accrual-table__pagination"
      :page-count="amountOfPages"
      :initial-page="page - 1"
      :click-handler="pageChange"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import accruals from '@/api/accruals'
import Title from '@/basic/Title'
import SlotTable from '@/basic/SlotTable.vue'
import AccrualNumber from '@/widgets/accruals-table/components/cells/AccrualNumber.vue'
import AccrualDate from '@/widgets/accruals-table/components/cells/AccrualDate.vue'
import AccrualStatus from '@/widgets/accruals-table/components/cells/AccrualStatus.vue'
import AccrualApproval from '@/widgets/accruals-table/components/cells/AccrualApproval.vue'

export default defineComponent ({
  name: 'AccrualTable',
  components: {
    Title,
    SlotTable,
    AccrualNumber,
    AccrualDate,
    AccrualStatus,
    AccrualApproval,
  },
  data: () => ({
    limit: 10,
    page: 1,
    amountOfPages: 1,
    requests: [],
    tableHeaders: [
      'ID',
      'Дата',
      'Статус',
      'ID пользователя',
      'Сумма',
      'Одобрение'
    ],
  }),
  async mounted () {
    this.fetchRequests()
  },
  methods: {
    pageChange (page) {
      this.page = page
    },
    async fetchRequests () {
      try {
        const { data: response, } = await accruals.getAccruals(
          this.limit,
          this.page
        )

        if (response.success) {
          this.requests = response?.result?.requests || []
          this.amountOfPages = response?.result?.pagination.pages.totalCountPage
        }
      } catch (error) {
        console.warn(error)
      }
    },
  },
  watch: {
    page () {
      this.fetchRequests()
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/accruals-table/styles/typography.scss';

.accrual-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__pagination {
    display: flex;
    gap: 8px;
    ::v-deep & > li {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border: 1.5px solid $primary-100;
      border-radius: 8px;
      & a {
        transition: .2s all ease;
        color: $primary-600;
        @include base-text;
        &::after {
          content: '';
          position: absolute;
          inset: 0;
        }
      }
      &:hover {
        & a {
         color: $primary-200;
        }
      }
    }
    ::v-deep & > li.active {
      background-color: $primary-100;
    }
    ::v-deep & li:first-child,
    ::v-deep & li:last-child {
      display: none;
    }
  }
}
</style>
